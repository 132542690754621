define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["en-us", {
    "add_on_form": {
      "more_info": "More info",
      "subheading": {
        "ticketless_upgrade": "Purchase add-ons with your upgrade",
        "tickets": "Purchase add-ons with your tickets"
      }
    },
    "add_ons": "Add-ons",
    "artist_follow_card": {
      "confirm_question": "Do you want to be notified the next time {artistName} is in your area?",
      "fallback_action": "Follow your favorite artists",
      "heading": "Know when your favorite artists are in {city}",
      "maybe_later": "Maybe Later",
      "messages_legal": "By clicking \"Yes,\" you agree to receive recurring alerts from Seated about artists you follow. Msg & data rates may apply. Text STOP to cancel, HELP for help.",
      "subheading": "You’ll get email and text message notifications whenever artists you follow have events near {city}.",
      "yes": "Yes!"
    },
    "artist_list": {
      "follow": "Follow",
      "following": "Following",
      "following_count": "Following ({count})",
      "no_results": "No results found. We’re adding a ton of artists daily so hopefully it won’t be a long wait!",
      "no_search_results_for": "No results for '{searchTerm}'",
      "recommended": "Recommended for you",
      "search_above": "You’re not following any artists yet so go ahead and search for your favorite artists above.",
      "search_again": "Search again",
      "search_artists": "Search for artists",
      "search_results": "Search results"
    },
    "autobuy": {
      "description": "Have Seated automatically secure tickets for you before they go on sale{isStartsAtKnown, select, true { {dateTime}} other {}}"
    },
    "billing_address": "Billing Address",
    "cancel": "Cancel",
    "confirm_order": {
      "add_on_delivery": "Add-on Delivery",
      "autobuy_info": "Your card will not be charged at this time. If tickets cannot be secured in advance, then you will be notified before tickets go on sale{isStartsAtKnown, select, true { <span>{dateTime}</span>} other {}}.",
      "email": "Email",
      "fees": "Fees",
      "greeting": "Hi, {firstName}.",
      "join_waitlist": "Join waitlist",
      "last_step": "Last step!",
      "name": "Name",
      "not_charged": "Your credit card has not been charged.",
      "not_charged_expanded": "Your card will not be charged at this time and you are not guaranteed tickets.",
      "payment": "Payment",
      "review_details": "Review the details below before submitting your {isBuyNow, select, true {order} other {request}}.",
      "shipping_address": "Shipping Address",
      "status": {
        "cancelled": "Your request has been <span class=\"font-bold\">cancelled</span>.",
        "not_selected": "Your request was <span class=\"font-bold\">not selected</span>.",
        "not_selected_waitlist": "Your waitlist request was <span class=\"font-bold\">not selected</span>.",
        "on_waitlist": "Your request is on the <span class=\"font-bold\">waitlist</span>.",
        "pending": "Your request is <span class=\"font-bold\">pending</span>.",
        "refunded": "Your request has been <span class=\"font-bold\">refunded</span>.",
        "selected": "Your request was <span class=\"font-bold\">selected</span>!"
      },
      "submit_label": {
        "buy_now": "Buy now {price}",
        "place_order": "Place order",
        "save_changes": "Save changes",
        "submit_request": "Submit request"
      },
      "ticket_delivery": "Ticket Delivery",
      "ticket_requests_selected": "Ticket requests will be selected {dateTime}.",
      "ticketless_upgrade_delivery": "Upgrade Delivery",
      "waitlist_info": "We'll automatically complete your purchase if additional tickets become available."
    },
    "confirm_release_tickets": {
      "are_you_sure": "Are you sure you want to release your tickets?",
      "are_you_sure_ticketless_upgrade": "Are you sure you want to release your upgrades?",
      "no_keep": "No, keep my {quantity, plural, one {# Ticket} other {# Tickets}}",
      "no_keep_button": "No, keep my tickets",
      "no_keep_button_ticketless_upgrade": "No, keep my upgrades",
      "no_keep_ticketless_upgrade": "No, keep my {quantity, plural, one {# Ticket} other {# Tickets}}",
      "reason_placeholder": "Type your reason here",
      "warning": "Searching for {quantity, plural, one {# ticket} other {# tickets}} requires you to release your current tickets.",
      "warning_ticketless_upgrade": "Searching for {quantity, plural, one {# upgrade} other {# upgrades}} requires you to release your current upgrades.",
      "yes_release": "Yes, release my {quantity, plural, one {# Ticket} other {# Tickets}}",
      "yes_release_ticketless_upgrade": "Yes, release my {quantity, plural, one {# Upgrade} other {# Upgrades}}"
    },
    "date_tbd": "Date TBD",
    "delivery_summary_item": {
      "add_ons": {
        "description": {
          "digital": "These items will be emailed to you.",
          "online": "These items will be emailed to you.",
          "physical": "These items will be mailed to you."
        }
      },
      "delivery_method_for_tiers": "Delivery method for {tierNames}",
      "ticketless_upgrades": {
        "description": "Upgrade details will be emailed to you prior to the event."
      },
      "tickets": {
        "description": {
          "axs_mobile": "Your AXS Mobile tickets will be transferred about 48 hours prior to the event.",
          "digital": "These PDF tickets will be emailed to you about 48 hours prior to the event.",
          "flash_seats": "Your mobile tickets must be claimed in the Flash Seats mobile app.",
          "online": "The link for the show will be emailed to you shortly before it goes live.",
          "online_live": "The link for the live event will be provided after completing your purchase.",
          "physical": "These physical tickets will be mailed to you.",
          "tm_mobile": "Your Ticketmaster Mobile tickets will be transferred about 48 hours prior to the event.",
          "will_call": "These tickets will be claimed at the venue box office."
        }
      }
    },
    "delivery_type_text": {
      "axs_mobile": "AXS Mobile",
      "digital": "Digital Delivery",
      "flash_seats": "Flash Seats",
      "online": "Online",
      "physical": "Physical Delivery",
      "tm_mobile": "Ticketmaster Mobile",
      "will_call": "Will Call"
    },
    "edit": "Edit",
    "edp_header": {
      "buy_tickets": "Buy Tickets",
      "buy_upgrade": "Buy Upgrade",
      "free_tickets": "Tickets",
      "join_waitlist": "Join Waitlist",
      "request_tickets": "Request Tickets",
      "sign_up": "Sign Up"
    },
    "event_complete": {
      "header": {
        "purchased": "Your tickets have been purchased.",
        "purchased_ticketless_upgrade": "Your upgrades have been purchased.",
        "received": "Your request has been received.",
        "secured": "Your tickets have been secured."
      },
      "hide_details": "Hide Details",
      "show_details": "Show Details"
    },
    "event_reminders": {
      "quantity_description": "{isStartsAtKnown, select, true {Tickets are} other {{isSingleTier, select, true {Tickets will be no more than} other {Ticket options will be}}}} {price}. How many tickets would you like to request?"
    },
    "events": {
      "age_restriction": "Age Restriction",
      "all_ages": "All Ages",
      "all_sales_final": "All sales through Seated are final.",
      "door_time": "Door Time",
      "event_details": "Event Details",
      "event_notice": "Event Notice",
      "info_form_email_lottery": "We collect your name and email address to deliver the order confirmation and tickets (if selected).",
      "lottery_closes": "Requests Close",
      "lottery_draws": "Random Selection",
      "lottery_explain": "All requests, regardless of when they are placed, will be treated equally by the ticket request system.",
      "lottery_opens": "Request Period",
      "no_ticketless_upgrades_body": "No upgrades were available based on your search.",
      "no_tickets_body": "No tickets were available based on your search.",
      "no_tickets_header": "Try selecting another quantity or section.",
      "payment_form": {
        "bottom_label": "We collect your credit card information to pay for tickets.",
        "bottom_label_ticketless_upgrade": "We collect your credit card information to pay for your upgrade.",
        "heading_card": "Please enter your credit card details",
        "heading_coupon": "Please enter your coupon code",
        "subheading": {
          "autobuy": "We will only charge your card if tickets are secured.",
          "buy_now_card": "Your card will only be charged after you confirm your order.",
          "buy_now_coupon": "To claim a complimentary ticket enter your coupon code below.",
          "lottery": "Your card will only be charged if your request is selected."
        }
      },
      "policy_notice": "In purchasing through Seated, you assume responsibility for adhering to all venue policies. We highly recommend you review all policies and conditions to enter on the venue’s website before attending the event.",
      "promoted_by": "This event is promoted by",
      "quantity_picker_desc": "Tickets are {isFreeEvent, select, true {free} other {{price}}}. How many tickets would you {isFreeEvent, select, true {like?} other {like to {buyNow, select, true {buy} other {request}}?}}",
      "quantity_picker_desc_ticketless_upgrade": "Upgrades are {price}. How many upgrades would you like to {buyNow, select, true {buy} other {request}}?",
      "requests_selected": "Requests Selected",
      "requests_selected_details": "If additional tickets become available, then waitlist requests will be randomly selected.",
      "start_time": "Start Time",
      "starting_at": "Starting at",
      "ticket_price": "Ticket Price",
      "ticket_type": "Ticket Type",
      "tickets_are_free": "Tickets are Free",
      "tickets_purchased": "Tickets Purchased",
      "tickets_purchased_details": "If selected, you’ll be assigned tickets and automatically charged.",
      "waitlist_explain": "Join the waitlist - we'll automatically complete your purchase if additional tickets become available. Cancel your request risk-free at any time.",
      "waitlist_opens": "Join the Waitlist",
      "waitlist_opens_details": "Enter your ticket preferences along with payment information."
    },
    "expired_cart": {
      "start_over": "Start Over",
      "ticketless_upgrades_released": "Your upgrades have been released.",
      "tickets_released": "Your tickets have been released.",
      "timer_expired": "Your cart timer has expired."
    },
    "faq": "FAQ",
    "follow_later": {
      "follow_more_artists": "Follow More Artists",
      "got_it_thanks": "Got it, thanks.",
      "return_to_domain": "Return to {domain}"
    },
    "free": "Free",
    "info_form": {
      "age_error": {
        "invalid_date": "Date of birth is not a valid date.",
        "too_young": "You must be at least {minimumAge} years old to request tickets."
      },
      "age_privacy_message": "We collect your date of birth to verify you meet the minimum age requirements for this event.",
      "autofill": {
        "dob_day": "Birthday Day",
        "dob_month": "Birthday Month",
        "dob_year": "Birthday Year"
      },
      "country": "Country",
      "country_select_placeholder": "Select Country",
      "dob": {
        "day_placeholder": "DD",
        "month_placeholder": "MM",
        "title": "Date of Birth",
        "year_placeholder": "YYYY"
      },
      "email": "Email",
      "first_name": "First Name",
      "last_name": "Last Name",
      "name_label": "We collect your name to know who has purchased tickets.",
      "postal_code": "Postal Code",
      "postal_code_error": "Not a valid postal code for {country}.",
      "unsupported_postal_code_error": "We currently do not support postal codes from {country}."
    },
    "join": "Join",
    "join_waitlist": {
      "back_text": "No, go back to my request",
      "proceed_text": "Yes, join waitlist",
      "subtitle": "If tickets become available, then you will be automatically charged.",
      "title": "Are you sure you want to join the waitlist?"
    },
    "leave_waitlist": {
      "back_text": "No, stay on waitlist",
      "proceed_text": "Yes, leave waitlist",
      "subtitle": "You will not receive tickets and your card will not be charged.",
      "title": "Are you sure you want to leave the waitlist?"
    },
    "live": "Live",
    "live_stream": {
      "placeholder": "Live stream starting soon"
    },
    "loading": "Loading...",
    "location_selector": {
      "your_location": "Your location"
    },
    "location_selector_overlay": {
      "search_your_location": "Search your location",
      "set_location": "Set Location",
      "unable_to_load_map": "Unable to load map"
    },
    "mailing_address": "Mailing Address",
    "mailing_address_form": {
      "country": "Country",
      "heading": {
        "billing": "What is your billing address?",
        "mailing": "What is your mailing address?"
      },
      "info_message": {
        "billing": "We require your billing address to calculate any required taxes.",
        "mailing": "We require your mailing address in order to mail physical items, like tickets and merch.",
        "mailing_ticketless_upgrade": "We require your mailing address in order to mail physical items, like merch."
      },
      "street_address": "Street Address"
    },
    "menu_bar": {
      "back_to_summary": "Back to summary"
    },
    "multiply": "x",
    "new_event_notifications": "New event notifications",
    "next": "Next",
    "no_tickets": {
      "search_again": "Search Again",
      "search_on_domain": "Search on {domain}",
      "try_again": "or try again"
    },
    "notification_confirm_form": {
      "done": "Done",
      "heading": "Let's setup your notifications."
    },
    "notification_settings": {
      "account_details": "Account Details",
      "change_contact": "To change your email or phone please <a href=\"https://support.seated.com/hc/en-us/requests/new\" target=\"_blank\" rel=\"noopener\" class=\"underline\">contact us</a>.",
      "contact_us": "Contact us",
      "here_for_you": "We're always here for you.",
      "integrations_heading": "Integrations",
      "integrations_subheading": "Any accounts you connect to Seated can be managed here.",
      "log_out": "Log out",
      "miles": "miles",
      "need_help": "Need Help?",
      "privacy_policy": "Privacy policy"
    },
    "notifications": {
      "apple_music": "Apple Music",
      "connect_music_services": "Connect to Streaming Service",
      "date_tbd": "Date TBD",
      "disabled_alert": "You’re not getting any notifications about the artists you follow.",
      "disconnect_button": "Disconnect from Seated",
      "dont_miss": "Don’t miss your favorite artists",
      "link": "Notifications",
      "now_following_artist": "You’re now following {artistName}",
      "settings": "Settings",
      "show_subtitle": {
        "announcement": "Artists you follow announced new events",
        "upcoming": "Artists you follow have events near you this week"
      },
      "show_title": {
        "announcement": "New events near you",
        "upcoming": "Coming up this week"
      },
      "subheading": "You’ll get email and text message notifications whenever artists you follow have events near",
      "turn_on": "Turn notifications on"
    },
    "notifications_logged_out": {
      "heading": "You’ve successfully logged out.",
      "login": "Login",
      "subheading": "You can login any time to manage the artists you follow."
    },
    "notifications_login": {
      "heading": "What is your cell phone number?",
      "subheading": "We'll send you a one-time verification number.",
      "welcome_back": "Welcome back!"
    },
    "on_sale_date_list_item": {
      "not_on_sale": "Tickets not yet on sale"
    },
    "on_sale_dates": {
      "face_value_tickets": "We'll alert you as tickets become available.",
      "get_password": "Get Password"
    },
    "opt_ins": {
      "confirm_age": "I confirm that I am {minimumAge} years of age or older",
      "error": "You must confirm your age to continue",
      "minimum_age": "This event is {minimumAge}+."
    },
    "opt_ins_modal": {
      "email_header": "Email Preferences",
      "header": "Confirm your preferences",
      "sms_header": "SMS Preferences",
      "sub_header": "Please confirm your preferences below before continuing."
    },
    "order": "Order",
    "order_complete": {
      "confirmation_email": "A confirmation has been sent to {email}.",
      "header": {
        "buy_now_updated": "Your order has been updated.",
        "cancelled": "Your request has been cancelled.",
        "not_on_waitlist": "You are no longer on the waitlist.",
        "on_waitlist": "You are on the waitlist.",
        "refund_approved": "Your refund has been approved.",
        "refund_pending": "Your refund request has been received.",
        "refunded": "Your order has been refunded.",
        "refunded_charity": "Your refund has been donated.",
        "updated": "Your request has been updated."
      },
      "more_details": "More Details",
      "more_ticket_details": "If more tickets become available, then requests will be selected randomly from the waitlist. Your card will only be charged if your request is selected.",
      "refund_details": "The refund will appear on your credit card within 7-10 business days.",
      "refund_details_approved": "This event offers multiple refund options, please check your confirmation email for further details.",
      "refund_details_charity": "Seated is grateful for your generous decision to donate to The Busyhead Project.",
      "refund_details_credit": "Your credit balance has been updated and can be spent immediately.",
      "refund_request_details": "We appreciate your patience as our team works to review and respond to each request.",
      "tickets_selected_at": "Ticket requests will be selected <span class=\"font-bold\">{dateTime}</span>."
    },
    "order_details": {
      "edit_ticket_holders": "Edit Ticket Holders",
      "secure_tickets": "We are currently trying to secure your tickets."
    },
    "order_info": {
      "buy_now": {
        "heading": {
          "ticketless_upgrade": "Great! We've held {quantity, plural, one {# upgrade} other {# upgrades}}",
          "tickets": "Great! We've held {quantity, plural, one {# ticket} other {# tickets}}"
        }
      },
      "heading": "What is your name and email?"
    },
    "order_login": {
      "instructions": "We'll send you a one time verification number to access your order details.",
      "invalid_verification": "Verification number invalid. Please double check and try again.",
      "not_invalid_grant": "Issue validating number. Please try again in a few minutes.",
      "unsupported_number": "To log into your order, please <a href=\"https://support.seated.com/hc/en-us/requests/new\" target=\"_blank\" rel=\"noopener\" class=\"underline\">contact us</a>.",
      "welcome_back": "Welcome back, {firstName}!"
    },
    "order_tickets": {
      "update_ticket_holder_form": {
        "heading": "Change Name for Pickup"
      }
    },
    "order_timeout": {
      "heading": "Connection Error",
      "start_over": "Start Over",
      "subheading": "We were unable to determine if your order was placed successfully due to a network connection issue. Please check your email for an order confirmation before trying again."
    },
    "orders": {
      "cancel_request": "Cancel request",
      "claim": "Claim",
      "leave_waitlist": "Leave waitlist",
      "refund_approved": "Great news! Your refund has been approved.",
      "refund_options": "This event has multiple refund options. Click below to claim your refund.",
      "request_refund": "Request Refund"
    },
    "orders_cancel": {
      "back_text": "No, keep my request",
      "proceed_text": "Yes, cancel my request",
      "subtitle": "Your request will not be selected for this event and your card will not be charged.",
      "title": "Are you sure you want to cancel your request?"
    },
    "password_signup": {
      "window_closed": "The signup window is now closed."
    },
    "payment_form": {
      "autofill": {
        "credit_card_number": "Credit Card Number",
        "cvv": "CVV",
        "exp_month": "Expiration Month",
        "exp_year": "Expiration Year",
        "name_on_card": "Name on Card"
      },
      "button_label": {
        "add_card": "Add card",
        "add_coupon": "Add coupon"
      },
      "coupon_code": "Coupon Code",
      "credit_card_number": "Card Number",
      "cvc": "CVC",
      "label": "Payment Form",
      "name_on_card": "Name on Card"
    },
    "phone_login": {
      "faq_try_again": "An unexpected error has occurred - please read this <a href=\"https://support.seated.com/hc/en-us/articles/15368268079895-Invalid-Phone-Number-FAQ\" target=\"_blank\" rel=\"noopener\" class=\"underline\">FAQ</a> before trying again.",
      "heading": "What is your cell phone number?",
      "invalid_phone_number": "Phone number invalid. Please try a different one.",
      "invalid_verification": "Verification number invalid. Please confirm verification number or use a different phone number.",
      "not_invalid_grant": "Issue validating number. Please try again in a few minutes.",
      "phone_label": "By providing your phone number, you allow Seated to send a one-time verification code and notifications related to this event. {additionalMessages}Msg & data rates may apply. <a href=\"https://support.seated.com/hc/en-us/articles/115006196728-Privacy-Policy\" target=\"_blank\" rel=\"noopener\" class=\"underline\">Privacy Policy</a>",
      "phone_label_no_additional_messages": "No additional messages will be sent. ",
      "phone_label_opt_in_additional_messages": "Your phone number will not be shared unless you opt-in below. ",
      "phone_number": "Phone Number",
      "resend_verification": "Resend verification",
      "sms_stopped": "Text message delivery to this number is disabled. <a href=\"https://support.seated.com/hc/en-us/articles/4500154228503\" target=\"_blank\" rel=\"noopener\" class=\"underline\">Learn how to enable it.</a>",
      "subheading": "We'll send you a one-time True Fan Verification Number.",
      "timeout": "Issue validating phone number. Please try again in a few minutes.",
      "verification_delay": "The verification number could take up to 30 seconds to arrive.",
      "verification_pending": "Enter your 4-digit verification number below.",
      "verify": "Verify"
    },
    "privacy_policy": "Privacy Policy",
    "quantity_picker": {
      "heading": "How many tickets?",
      "heading_ticketless_upgrade": "How many upgrades?",
      "select_next_step": "You'll be able to select specific tickets on the next step.",
      "select_next_step_ticketless_upgrade": "You'll be able to select specific upgrades on the next step. Please note: Upgrades do NOT include tickets to the event. You must already have a ticket or must purchase a ticket separately.",
      "ticket_type": "Tickets are all {ticketType}",
      "ticket_type_ticketless_upgrade": "Upgrades are all {ticketType}"
    },
    "refunds": {
      "cannot_attend": "Can't attend",
      "error": "There was an error processing your refund. Please refresh and try again.",
      "order_mistake": "Ordered by mistake",
      "other": "Other",
      "poor_seats": "Poor seat location",
      "refund_sure": "Are you sure you want to request a refund on your order?",
      "request_a_refund": "Request a Refund",
      "select_reason": "Select a refund reason",
      "too_expensive": "Too expensive",
      "yes_refund": "Yes, request refund"
    },
    "replace_card": "Replace card",
    "replace_coupon": "Replace coupon",
    "request": "Request",
    "seat": {
      "section_row_number": "Section {section}, Row {row}, {number}"
    },
    "seated": "seated",
    "settings_form": {
      "location": "Location",
      "location_area": "You will only receive notifications for events in this area.",
      "location_saved": "Location saved.",
      "notifications": "Notifications",
      "notifications_disabled": "Notifications are disabled. Turn them on by checking the boxes below.",
      "select_your_location": "Select your location",
      "settings_saved": "Settings saved.",
      "toggle_label": {
        "announcements": "New event announcements",
        "upcoming": "Reminders 7 days before an event"
      },
      "which_notifications": "Which notifications would you like to receive?"
    },
    "shipping": "Shipping",
    "stripe": {
      "error": {
        "card_declined": "Your card was declined.",
        "card_declined_card_not_supported": "Your card was declined.",
        "expired_card": "Your card has expired.",
        "incomplete_card": "Please enter your card details.",
        "incomplete_cvc": "The card security code is incomplete.",
        "incomplete_expiry": "The expiration date is incomplete.",
        "incomplete_number": "The card number is incomplete.",
        "incorrect_cvc": "Your card's security code is incorrect.",
        "incorrect_number": "Your card number is incorrect.",
        "invalid_cvc": "Your card's security code is invalid.",
        "invalid_expiry_month": "Your card's expiration month is invalid.",
        "invalid_expiry_month_past": "Your card's expiration month is in the past.",
        "invalid_expiry_year": "Your card's expiration year is invalid.",
        "invalid_expiry_year_past": "Your card's expiration year is in the past.",
        "invalid_number": "The card number is not a valid credit card number.",
        "process_error_intransient": "An error occurred while processing your card.",
        "processing_error": "An error occurred while processing your card. Try again in a little bit.",
        "unexpected": "An unexpected error has occurred."
      }
    },
    "tax": "Tax",
    "ticket": "{quantity, plural, one {Ticket} other {Tickets}}",
    "ticket_delivery_info": {
      "resend_my_tickets": "Resend My Tickets",
      "tickets_emailed_to": "Your tickets were emailed to {email} on {deliveryDates}"
    },
    "ticket_delivery_summary_item": {
      "delivery_method_for_tiers": "Delivery method for {tierNames}",
      "description": {
        "axs_mobile": "Your tickets must be claimed in the AXS Mobile app.",
        "digital": "These PDF tickets will be emailed to you.",
        "flash_seats": "Your mobile tickets must be claimed in the Flash Seats mobile app.",
        "online": "The link for the show will be emailed to you shortly before it goes live.",
        "tm_mobile": "Your tickets must be claimed in the Ticketmaster Mobile app.",
        "will_call": "These tickets will be claimed at the venue box office."
      }
    },
    "ticket_details_display": {
      "delivery_method": "Delivery Method",
      "equal_requests": "All requests, regardless of when they are placed, will be treated equally by the ticket request system.",
      "fees": "fees",
      "for": "for",
      "requests_made_before": "Requests can be made at any time before",
      "ticket_details": "Ticket Details",
      "upgrade_details": "Upgrade Details"
    },
    "ticket_quantity": "{quantity, plural, one {# Ticket} other {# Tickets}}",
    "tickets": "Tickets",
    "tier_picker": {
      "complimentary": "Complimentary",
      "multiple_options": "You can select multiple options and the best available tickets will be assigned.",
      "multiple_options_ticketless_upgrade": "You can select multiple options and the best available option will be assigned. Please note: Upgrades do NOT include tickets to the event. You must already have a ticket or must purchase a ticket separately.",
      "starts_at_unknown": "The maximum you will be charged is the price displayed below.",
      "unavailable": "Unavailable",
      "which_tickets": "Which tickets would you like to request?",
      "which_tickets_free": "Which tickets would you like?",
      "which_upgrades": "Which upgrades would you like to request?"
    },
    "total": "Total",
    "unprocessable_entity": {
      "header": "Oops",
      "subheader": "There was a problem accessing this page, please contact <a class=\"underline\" href=\"mailto:support@seated.com?subject=Unable to visit ticket page\" data-test-email-link=\"\">support@seated.com</a> for more information."
    },
    "up_to": "Up to",
    "upgrades": "Upgrades",
    "venue": "Venue",
    "verification_form": {
      "cc_month_placeholder": "MM",
      "cc_year_placeholder": "YY"
    },
    "waves_content": {
      "how_it_works": "How it works",
      "how_it_works_summary": "We expect a high demand for tickets. To ensure a fair process, we will be releasing tickets in waves. Each wave will have a limited number of tickets available for purchase. Please see the details for each wave above."
    }
  }], ["es-mx", {
    "add_on_form": {
      "more_info": "Más información",
      "subheading": {
        "ticketless_upgrade": "Compre complementos con su actualización",
        "tickets": "Compra complementos con tus entradas"
      }
    },
    "add_ons": "Complementos",
    "artist_follow_card": {
      "confirm_question": "¿Te gustaría ser notificado la próxima vez que {artistName} este en tu área?",
      "fallback_action": "Sigue a tus artistas favoritos",
      "heading": "Entérate cuando tus artistas favoritos estén en la ciudad {city}",
      "maybe_later": "Quizás más tarde",
      "messages_legal": "Al hacer clic en \"Sí\", aceptas recibir alertas periódicas de Seated sobre los artistas que sigues. Se pueden aplicar tarifas de mensajes y datos. Envía STOP para cancelar y HELP para obtener ayuda.",
      "subheading": "Recibirás una notificación vía correo electrónico y mensaje de texto cada vez que el artista que sigues tenga eventos cerca de {city}.",
      "yes": "¡Sí!"
    },
    "artist_list": {
      "follow": "Seguir",
      "following": "Siguiendo",
      "following_count": "Siguiendo ({count})",
      "no_results": "No se encontraron resultados. ¡Estamos añadiendo muchos artistas diariamente así que confiamos que la espera sea corta!",
      "no_search_results_for": "No se encontraron resultados para '{searchTerm}'",
      "recommended": "Recomendado para ti",
      "search_above": "Todavía no estás siguiendo a ningún artista, así que ve y busca arriba a tus artistas favoritos.",
      "search_again": "Buscar otra vez",
      "search_artists": "Buscar artistas",
      "search_results": "Resultados de la búsqueda"
    },
    "autobuy": {
      "description": "Seated asegure automáticamente las entradas antes de que salgan a la venta{isStartsAtKnown, select, true { {dateTime}} other {}}"
    },
    "billing_address": "Dirección de facturación",
    "cancel": "Cancelar",
    "confirm_order": {
      "add_on_delivery": "Entrega de complementos",
      "autobuy_info": "Su tarjeta no se cargará en este momento. Si los boletos no se pueden asegurar con anticipación, se le notificará antes de que los boletos salgan a la venta{isStartsAtKnown, select, true { <span>{dateTime}</span>} other {}}.",
      "email": "Email",
      "fees": "Tarifa",
      "greeting": "Hola, {firstName}.",
      "join_waitlist": "Ünete a la lista de espera",
      "last_step": "¡Último paso!",
      "name": "Nombre",
      "not_charged": "No se han realizado cargos a tu tarjeta de crédito.",
      "not_charged_expanded": "No se realizarán cargos a tu tarjeta en este momento y no tienes garantizados tus boletos.",
      "payment": "Pago",
      "review_details": "Revise los detalles a continuación antes de enviar su {isBuyNow, select, true {orden} other {solicitud}}.",
      "shipping_address": "Dirección de Envío",
      "status": {
        "cancelled": "Tu solicitud ha sido <span class=\"font-bold\">cancelada</span>.",
        "not_selected": "Tu solicitud <span class=\"font-bold\">no fue seleccionada</span>.",
        "not_selected_waitlist": "Tu solicitud para la lista de espera <span class=\"font-bold\">no fue seleccionada</span>.",
        "on_waitlist": "Tu solicitud esta en la <span class=\"font-bold\">lista de espera</span>.",
        "pending": "Tu solicitud esta <span class=\"font-bold\">pendiente</span>.",
        "refunded": "Tu solicitud ha sido <span class=\"font-bold\">reembolsada</span>.",
        "selected": "Tu solicitud fue <span class=\"font-bold\">seleccionada</span>!"
      },
      "submit_label": {
        "buy_now": "Comprar ahora {price}",
        "place_order": "Realizar pedido",
        "save_changes": "Guardar cambios",
        "submit_request": "Enviar solicitud"
      },
      "ticket_delivery": "Entrega de entradas",
      "ticket_requests_selected": "Las solicitudes de boletos serán seleccionadas en {dateTime}.",
      "ticketless_upgrade_delivery": "Entrega de actualización",
      "waitlist_info": "Nosotros completaremos automaticamente tu compra si se hacen disponibles boletos adicionales."
    },
    "confirm_release_tickets": {
      "are_you_sure": "¿Estás seguro de querer liberar tus boletos?",
      "are_you_sure_ticketless_upgrade": "¿Estás seguro de querer liberar tus actualizaciones?",
      "no_keep": "No, conserva {quantity, plural, one {# Boleto} other {# Boletos}}",
      "no_keep_button": "No, quédate mis boletos",
      "no_keep_button_ticketless_upgrade": "No, quédate mis actualizaciones",
      "no_keep_ticketless_upgrade": "No, conserva {quantity, plural, one {# Actualización} other {# Actualizaciones}}",
      "reason_placeholder": "Escriba su razón aquí",
      "warning": "Buscar {quantity, plural, one {# boleto} other {# boletos}} requiere que liberes tus boletos actuales.",
      "warning_ticketless_upgrade": "Buscar {quantity, plural, one {# actualización} other {# actualizaciones}} requiere que liberes tus actualizaciones actuales.",
      "yes_release": "Sí, libera {quantity, plural, one {# Boleto} other {# Boletos}}",
      "yes_release_ticketless_upgrade": "Sí, libera {quantity, plural, one {# Actualización} other {# Actualizaciones}}"
    },
    "date_tbd": "Fecha por determinar",
    "delivery_summary_item": {
      "add_ons": {
        "description": {
          "digital": "Estos artículos le serán enviados por correo electrónico.",
          "online": "Estos artículos le serán enviados por correo electrónico.",
          "physical": "Estos artículos le serán enviados por correo."
        }
      },
      "delivery_method_for_tiers": "Método de entrega para {tierNames}",
      "ticketless_upgrades": {
        "description": "Los detalles de la actualización le serán enviados por correo electrónico antes del evento."
      },
      "tickets": {
        "description": {
          "axs_mobile": "Tus boletos móviles de AXS serán transferidos aproximadamente 48 horas antes del evento.",
          "digital": "Estos boletos en formato PDF serán enviados a tu correo electrónico aproximadamente 48 horas antes del evento.",
          "flash_seats": "Sus boletos móviles deben ser reclamados en la aplicación móvil Flash Seats.",
          "online": "El enlace para el programa se le enviará por correo electrónico poco antes de que se publique.",
          "online_live": "El enlace para el evento en vivo se proporcionará después de completar su compra.",
          "physical": "Estos boletos físicos le serán enviados por correo.",
          "tm_mobile": "Tus boletos móviles de Ticketmaster serán transferidos aproximadamente 48 horas antes del evento.",
          "will_call": "Estas entradas se reclamarán en la taquilla del recinto."
        }
      }
    },
    "delivery_type_text": {
      "axs_mobile": "AXS Mobile",
      "digital": "Entrega Digital",
      "flash_seats": "Flash Seats",
      "online": "En línea",
      "physical": "Entrega Física",
      "tm_mobile": "Ticketmaster Móvil",
      "will_call": "Taquilla"
    },
    "edit": "Editar",
    "edp_header": {
      "buy_tickets": "Comprar Boletos",
      "buy_upgrade": "Comprar actualización",
      "free_tickets": "Boletos",
      "join_waitlist": "Unirse a la Lista de Espera",
      "request_tickets": "Solicitar Boletos",
      "sign_up": "Inscribirse"
    },
    "event_complete": {
      "header": {
        "purchased": "Tus boletos han sido comprados.",
        "purchased_ticketless_upgrade": "Se han comprado sus actualizaciones.",
        "received": "Tu solicitud ha sido recibida.",
        "secured": "Tus boletos han sido asegurados."
      },
      "hide_details": "Ocultar Detalles",
      "show_details": "Mostrar Detalles"
    },
    "event_reminders": {
      "quantity_description": "{isStartsAtKnown, select, true {Los boletos son} other {{isSingleTier, select, true {Las entradas no serán más de} other {Las opciones de entradas serán}}}} {price}. ¿Cuántas entradas te gustaría solicitar?"
    },
    "events": {
      "age_restriction": "Restricción de Edad",
      "all_ages": "Todas las Edades",
      "all_sales_final": "Todas las ventas a través de Seated son definitivas.",
      "door_time": "Hora de Acceso",
      "event_details": "Detalles del evento",
      "event_notice": "Aviso de evento",
      "info_form_email_lottery": "Requerimos tu nombre y dirección de correo electrónico para entregar la confirmación de la orden y los boletos (si es seleccionada).",
      "lottery_closes": "Solicitudes Cerrar",
      "lottery_draws": "Selección Aleatoria",
      "lottery_explain": "Todas las solicitudes, independientemente de cuándo fueron ingresadas, serán tratadas con la misma prioridad por el sistema de solicitud de boletos.",
      "lottery_opens": "Periodo de solicitud",
      "no_ticketless_upgrades_body": "No hubo actualizaciones disponibles según su búsqueda.",
      "no_tickets_body": "No hubo disponibilidad de boletos basándose en tu búsqueda.",
      "no_tickets_header": "Intenta seleccionar otra cantidad o sección.",
      "payment_form": {
        "bottom_label": "Requerimos la información de su tarjeta de crédito para pagar por los boletos.",
        "bottom_label_ticketless_upgrade": "Recopilamos la información de su tarjeta de crédito para pagar su actualización.",
        "heading_card": "Por favor ingresa los detalles de tu tarjeta de crédito",
        "heading_coupon": "Por favor ingrese su código de cupón",
        "subheading": {
          "autobuy": "Solo cobraremos su tarjeta si los boletos están asegurados.",
          "buy_now_card": "Su tarjeta ÚNICAMENTE recibirá un cargo después de confirmar la orden en la siguiente pantalla.",
          "buy_now_coupon": "Para reclamar un boleto de cortesía ingrese su código de cupón a continuación.",
          "lottery": "Su tarjeta ÚNICAMENTE recibirá un cargo si su solicitud es seleccionada."
        }
      },
      "policy_notice": "Al comprar a través de Seated, usted asume la responsabilidad de cumplir con todas las políticas del lugar. Le recomendamos ampliamente revisar todas las políticas y condiciones para ingresar en el sitio web del lugar antes de asistir al evento.",
      "promoted_by": "Este evento es promovido por",
      "quantity_picker_desc": "Los boletos {isFreeEvent, select, true {son gratuitos} other {cuestan {price}}}. ¿Cuántos boletos {isFreeEvent, select, true {deseas?} other {deseas {buyNow, select, true {comprar} other {solicitar}}?}}",
      "quantity_picker_desc_ticketless_upgrade": "Las actualizaciones cuestan {price}. ¿Cuántas actualizaciones deseas {buyNow, select, true {comprar} other {solicitar}}?",
      "requests_selected": "Solicitudes Seleccionadas",
      "requests_selected_details": "Si se hacen disponibles boletos adicionales, las solicitudes de la lista de espera serán seleccionadas aleatoriamente.",
      "start_time": "Hora de Inicio",
      "starting_at": "A partir de",
      "ticket_price": "Precio del Boleto",
      "ticket_type": "Tipo del Boleto",
      "tickets_are_free": "Tickets are Free",
      "tickets_purchased": "Los Boletos son Gratis",
      "tickets_purchased_details": "Si tu solicitud es seleccionada, se te asignarán boletos y serán automáticamente cobrados.",
      "waitlist_explain": "Únete a la lista de espera - nosotros completaremos tu compra automáticamente si boletos adicionales se vuelven disponibles. Cancela tu solicitud sin riesgo en cualquier momento.",
      "waitlist_opens": "Únete a la Lista de Espera",
      "waitlist_opens_details": "Establece las preferencias para tus boletos junto con la información de pago."
    },
    "expired_cart": {
      "start_over": "Comenzar de Nuevo",
      "ticketless_upgrades_released": "Se han lanzado sus actualizaciones.",
      "tickets_released": "Tus boletos han sido liberados.",
      "timer_expired": "El tiempo de tu carrito ha expirado."
    },
    "faq": "FAQ",
    "follow_later": {
      "follow_more_artists": "Seguir Más Artistas",
      "got_it_thanks": "Entendido, gracias.",
      "return_to_domain": "Regresar a {domain}"
    },
    "free": "Gratis",
    "info_form": {
      "age_error": {
        "invalid_date": "La fecha de nacimiento no es una fecha válida.",
        "too_young": "Debes tener al menos {minimumAge} años para solicitar entradas."
      },
      "age_privacy_message": "Recopilamos su fecha de nacimiento para verificar que cumple con los requisitos de edad mínima para este evento.",
      "autofill": {
        "dob_day": "Birthday Day",
        "dob_month": "Birthday Month",
        "dob_year": "Birthday Year"
      },
      "country": "País",
      "country_select_placeholder": "Seleccionar País",
      "dob": {
        "day_placeholder": "DD",
        "month_placeholder": "MM",
        "title": "Fecha de nacimiento",
        "year_placeholder": "AAAA"
      },
      "email": "Email",
      "first_name": "Nombre",
      "last_name": "Apellidos",
      "name_label": "Requerimos tu nombre para saber quién compró los boletos.",
      "postal_code": "Código Postal",
      "postal_code_error": "No es un código postal válido para {country}.",
      "unsupported_postal_code_error": "Actualmente no admitimos códigos postales de {country}."
    },
    "join": "Unirse",
    "join_waitlist": {
      "back_text": "No, vuelve a mi solicitud",
      "proceed_text": "Sí, únete a la lista de espera",
      "subtitle": "Si las entradas están disponibles, se le cobrará automáticamente.",
      "title": "¿Estás segura de que quieres unirte a la lista de espera?"
    },
    "leave_waitlist": {
      "back_text": "No, permanecer en la lista de espera",
      "proceed_text": "Si, abandonar lista de espera",
      "subtitle": "No recibirás boletos y no se harán cargos a tu tarjeta de crédito.",
      "title": "¿Estás seguro que quieres abandonar la lista de espera?"
    },
    "live": "En Vivo",
    "live_stream": {
      "placeholder": "La transmisión en vivo comenzará pronto"
    },
    "loading": "Cargando...",
    "location_selector": {
      "your_location": "Tu ubicación"
    },
    "location_selector_overlay": {
      "search_your_location": "Buscar tu ubicación",
      "set_location": "Establecer Ubicación",
      "unable_to_load_map": "No se puede cargar el mapa"
    },
    "mailing_address": "Dirección de envio",
    "mailing_address_form": {
      "country": "País",
      "heading": {
        "billing": "¿Cuál es su dirección de facturación?",
        "mailing": "¿Cuál es su dirección postal?"
      },
      "info_message": {
        "billing": "Requerimos su dirección de facturación para calcular los impuestos requeridos.",
        "mailing": "Requerimos su dirección postal para enviar artículos físicos, como boletos y merchandising.",
        "mailing_ticketless_upgrade": "Requerimos su dirección postal para enviar artículos físicos, como merchandising."
      },
      "street_address": "Dirección"
    },
    "menu_bar": {
      "back_to_summary": "Regresar al resumen"
    },
    "multiply": "x",
    "new_event_notifications": "Nuevas notificaciones de eventos",
    "next": "Siguiente",
    "no_tickets": {
      "search_again": "Buscar Nuevamente",
      "search_on_domain": "Buscar en {domain}",
      "try_again": "o intentar de nuevo"
    },
    "notification_confirm_form": {
      "done": "Hecho",
      "heading": "Vamos a configurar tus notificaciones."
    },
    "notification_settings": {
      "account_details": "Detalles de la Cuenta",
      "change_contact": "Para cambiar tu correo electrónico o número telefónico, por favor <a href=\"https://support.seated.com/hc/en-us/requests/new\" target=\"_blank\" rel=\"noopener\" class=\"underline\">contáctanos</a>.",
      "contact_us": "Contáctanos",
      "here_for_you": "Siempre estamos aquí para ti.",
      "integrations_heading": "Integraciones",
      "integrations_subheading": "Cualquier cuenta que conectes a Seated se puede gestionar aquí.",
      "log_out": "Salir",
      "miles": "millas",
      "need_help": "¿Necesitas Ayuda?",
      "privacy_policy": "Política de privacidad"
    },
    "notifications": {
      "apple_music": "Apple Music",
      "connect_music_services": "Conéctese al servicio de transmisión",
      "date_tbd": "Fecha por determinar",
      "disabled_alert": "No estás recibiendo notificaciones acerca de los artistas que sigues.",
      "disconnect_button": "Desconectar de Seated",
      "dont_miss": "No te pierdas a tus artistas favoritos",
      "link": "Notificaciones",
      "now_following_artist": "Ahora estás siguiendo a {artistName}",
      "settings": "Configuración",
      "show_subtitle": {
        "announcement": "Los artistas que sigues anunciaron nuevos eventos",
        "upcoming": "Los artistas que sigues tienen eventos cerca de ti esta semana"
      },
      "show_title": {
        "announcement": "Nuevos eventos cerca de ti",
        "upcoming": "Esta semana"
      },
      "subheading": "Recibirás correos electrónicos y notificaciones por mensajes de texto cuando artistas que sigas tengan eventos cerca",
      "turn_on": "Encender notificaciones"
    },
    "notifications_logged_out": {
      "heading": "Has salido de tu cuenta exitosamente.",
      "login": "Acceder",
      "subheading": "Puedes acceder en cualquier momento para gestionar que artistas sigues."
    },
    "notifications_login": {
      "heading": "¿Cuál es tu número móvil?",
      "subheading": "Te enviaremos un número de verificación.",
      "welcome_back": "¡Bienvenido de vuelta!"
    },
    "on_sale_date_list_item": {
      "not_on_sale": "Entradas aún no a la venta"
    },
    "on_sale_dates": {
      "face_value_tickets": "Te avisaremos cuando las entradas estén disponibles.",
      "get_password": "Obtener la contraseña"
    },
    "opt_ins": {
      "confirm_age": "Confirmo que tengo {minimumAge} años o más",
      "error": "Debes confirmar tu edad abajo para continuar",
      "minimum_age": "Este evento es {minimumAge}+."
    },
    "opt_ins_modal": {
      "email_header": "Preferencias de correo electrónico",
      "header": "Confirma tus preferencias",
      "sms_header": "Preferencias de SMS",
      "sub_header": "Confirme sus preferencias a continuación antes de continuar."
    },
    "order": "Orden",
    "order_complete": {
      "confirmation_email": "Una confirmación ha sido enviada a {email}.",
      "header": {
        "buy_now_updated": "Su pedido ha sido actualizado.",
        "cancelled": "Tu solicitud ha sido cancelada.",
        "not_on_waitlist": "Ya no te encuentras en la lista de espera.",
        "on_waitlist": "Estás en la lista de espera.",
        "refund_approved": "Tu reembolso ha sido aprobado.",
        "refund_pending": "Su solicitud de reembolso ha sido recibida.",
        "refunded": "Tu orden ha sido reembolsada.",
        "refunded_charity": "Su reembolso ha sido donado.",
        "updated": "Tu solicitud ha sido actualizada."
      },
      "more_details": "Más Detalles",
      "more_ticket_details": "Si se hacen disponibles boletos adicionales, las solicitudes de la lista de espera serán seleccionadas aleatoriamente. Solamente se hará un cargo a tu tarjeta de crédito si tu solicitud es seleccionada.",
      "refund_details": "El reembolso aparecerá en tu tarjeta de crédito dentro de 7-10 días hábiles.",
      "refund_details_approved": "Este evento ofrece múltiples opciones de reembolso, consulte su correo electrónico de confirmación para obtener más detalles.",
      "refund_details_charity": "Seated agradece su generosa decisión de donar al Busyhead Project.",
      "refund_details_credit": "Su saldo de crédito ha sido actualizado y puede gastarse de inmediato.",
      "refund_request_details": "Agradecemos su paciencia mientras nuestro equipo trabaja para revisar y responder a cada solicitud.",
      "tickets_selected_at": "Las solicitudes de boletos serán seleccionadas <span class=\"font-bold\">{dateTime}</span>."
    },
    "order_details": {
      "edit_ticket_holders": "Editar titulares de entradas",
      "secure_tickets": "Actualmente estamos tratando de asegurar sus boletos."
    },
    "order_info": {
      "buy_now": {
        "heading": {
          "ticketless_upgrade": "¡Genial! Hemos realizado {quantity, plural, one {# actualización} other {# actualizaciones}}",
          "tickets": "¡Genial! Hemos tenido {quantity, plural, one {# boleto} other {# boletos}}"
        }
      },
      "heading": "¿Cuál es su nombre y correo electrónico?"
    },
    "order_login": {
      "instructions": "Te enviaremos un código numérico para acceder a los detalles de tu orden.",
      "invalid_verification": "El número de verificación es inválido. Por favor revísalo e intenta nuevamente.",
      "not_invalid_grant": "Problema validando el número. Por favor intenta nuevamente en unos minutos.",
      "unsupported_number": "Para iniciar sesión en su pedido, por favor <a href=\"https://support.seated.com/hc/en-us/requests/new\" target=\"_blank\" rel=\"noopener\" class=\"underline\">contáctenos</a>.",
      "welcome_back": "¡Bienvenido de vuelta, {firstName}!"
    },
    "order_tickets": {
      "update_ticket_holder_form": {
        "heading": "Cambiar nombre para recogida"
      }
    },
    "order_timeout": {
      "heading": "Error de Conexión",
      "start_over": "Comenzar de Nuevo",
      "subheading": "No pudimos determinar si su pedido se realizó correctamente debido a un problema de conexión a la red. Verifique su correo electrónico para obtener una confirmación del pedido antes de volver a intentarlo."
    },
    "orders": {
      "cancel_request": "Cancelar solicitud",
      "claim": "Claim",
      "leave_waitlist": "Abandonar lista de espera",
      "refund_approved": "Great news! Your refund has been approved.",
      "refund_options": "This event has multiple refund options. Click below to claim your refund.",
      "request_refund": "Solicitar Reembolso"
    },
    "orders_cancel": {
      "back_text": "No, mantener mi solicitud",
      "proceed_text": "Sí, cancelar mi solicitud",
      "subtitle": "Tu solicitud no será seleccionada para este evento y no se harán cargos a tu tarjeta.",
      "title": "¿Estás seguro que deseas cancelar tu solicitud?"
    },
    "password_signup": {
      "window_closed": "La ventana de registro ya está cerrada."
    },
    "payment_form": {
      "autofill": {
        "credit_card_number": "Credit Card Number",
        "cvv": "CVV",
        "exp_month": "Expiration Month",
        "exp_year": "Expiration Year",
        "name_on_card": "Name on Card"
      },
      "button_label": {
        "add_card": "Agregar tarjeta",
        "add_coupon": "Añadir cupón"
      },
      "coupon_code": "Código promocional",
      "credit_card_number": "Número de tarjeta",
      "cvc": "CVC",
      "label": "Formulario de pago",
      "name_on_card": "Nombre en la Tarjeta"
    },
    "phone_login": {
      "faq_try_again": "Ha ocurrido un error inesperado. Lea estas <a href=\"https://support.seated.com/hc/en-us/articles/15368268079895-Invalid-Phone-Number-FAQ\" target=\"_blank\" rel=\"noopener\" class=\"underline\">FAQ</a> antes de volver a intentarlo.",
      "heading": "¿Cuál es tu número móvil?",
      "invalid_phone_number": "Número telefónico inválido. Por favor intenta uno diferente.",
      "invalid_verification": "Número de verificación inválido. Por favor confirma tu número de verificación o usa un número telefónico diferente.",
      "not_invalid_grant": "Problema validando número. Por favor intenta nuevamente en unos minutos.",
      "phone_label": "Al proporcionar su número de teléfono, permite que Seated envíe un código de verificación único y notificaciones relacionadas con este evento. {additionalMessages}Es posible que se apliquen tarifas de mensajes y datos. <a href=\"https://support.seated.com/hc/en-us/articles/115006196728-Privacy-Policy\" target=\"_blank\" rel=\"noopener\" class=\"underline\">Política de privacidad</a>",
      "phone_label_no_additional_messages": "No se enviarán mensajes adicionales. ",
      "phone_label_opt_in_additional_messages": "Su número de teléfono no se compartirá a menos que se registre a continuación. ",
      "phone_number": "Número Telefónico",
      "resend_verification": "Reenviar verificación",
      "sms_stopped": "La entrega de mensajes de texto a este número está deshabilitada. <a href=\"https://support.seated.com/hc/en-us/articles/4500154228503\" target=\"_blank\" rel=\"noopener\" class=\"underline\">Aprende como habilitarlo.</a>",
      "subheading": "Te enviaremos un Número de Verificación de Verdadero Fan.",
      "timeout": "Problema validando número telefónico. Por favor intenta nuevamente en unos minutos.",
      "verification_delay": "Puede tomar hasta 30 segundos el recibir el número de verificación.",
      "verification_pending": "Escribe tu número de verificación de 4 dígitos abajo.",
      "verify": "Verificar"
    },
    "privacy_policy": "Política de Privacidad",
    "quantity_picker": {
      "heading": "¿Cuántos tickets?",
      "heading_ticketless_upgrade": "¿Cuántas actualizaciones?",
      "select_next_step": "Podrás seleccionar boletos específicos en el siguiente paso.",
      "select_next_step_ticketless_upgrade": "Podrá seleccionar actualizaciones específicas en el siguiente paso. Tenga en cuenta: las mejoras NO incluyen entradas para el evento. Ya debe tener un boleto o debe comprar un boleto por separado.",
      "ticket_type": "Todos los boletos son {ticketType}",
      "ticket_type_ticketless_upgrade": "Las actualizaciones son todas {ticketType}"
    },
    "refunds": {
      "cannot_attend": "No puedo asistir",
      "error": "Hubo un error al procesar su reembolso. Por favor, actualice y pruebe de nuevo.",
      "order_mistake": "Ordenado por error",
      "other": "Otro",
      "poor_seats": "Mala ubicación del asiento",
      "refund_sure": "¿Estás seguro de que deseas solicitar un reembolso en tu pedido?",
      "request_a_refund": "Solicitar un reembolso",
      "select_reason": "Seleccione un motivo de reembolso",
      "too_expensive": "Demasiado caro",
      "yes_refund": "Sí, solicitar reembolso"
    },
    "replace_card": "Reemplazar tarjeta",
    "replace_coupon": "Reemplazar cupón",
    "request": "Solicitar",
    "seat": {
      "section_row_number": "Sección {section}, Fila {row}, {number}"
    },
    "seated": "seated",
    "settings_form": {
      "location": "Ubicación",
      "location_area": "Solamente recibirás notificaciones para eventos en esta área.",
      "location_saved": "Ubicación guardada.",
      "notifications": "Notificaciones",
      "notifications_disabled": "Las notificaciones están deshabilitadas. Habilítalas marcando los recuadros abajo.",
      "select_your_location": "Selecciona tu ubicación",
      "settings_saved": "Configuración guardada.",
      "toggle_label": {
        "announcements": "Anuncios de nuevos eventos",
        "upcoming": "Recordatorios 7 días antes de un evento"
      },
      "which_notifications": "¿Cuáles notificaciones querrías recibir?"
    },
    "shipping": "Envío",
    "stripe": {
      "error": {
        "card_declined": "No se admite tu tarjeta.",
        "card_declined_card_not_supported": "No se admite tu tarjeta.",
        "expired_card": "La tarjeta ha caducado.",
        "incomplete_card": "Por favor, introduce los datos de tu tarjeta.",
        "incomplete_cvc": "El código de seguridad de tu tarjeta está incompleto.",
        "incomplete_expiry": "La fecha de caducidad de tu tarjeta está incompleta.",
        "incomplete_number": "El número de tu tarjeta está incompleto.",
        "incorrect_cvc": "El código de seguridad de tu tarjeta es incorrecto.",
        "incorrect_number": "El número de tu tarjeta es incorrecto.",
        "invalid_cvc": "El código de seguridad de tu tarjeta no es válido.",
        "invalid_expiry_month": "La fecha de caducidad de tu tarjeta no es válida.",
        "invalid_expiry_month_past": "La fecha de caducidad de tu tarjeta ya ha pasado.",
        "invalid_expiry_year": "El año de caducidad de la tarjeta no es válido.",
        "invalid_expiry_year_past": "El año de caducidad de la tarjeta ya pasó.",
        "invalid_number": "El número de tu tarjeta no es válido.",
        "process_error_intransient": "Ha ocurrido un error durante el procesamiento de tu tarjeta.",
        "processing_error": "Hubo un error durante el procesamiento de la tarjeta. Vuelve a intentarlo en unos minutos.",
        "unexpected": "Ha ocurrido un error inesperado."
      }
    },
    "tax": "Impuesto",
    "ticket": "{quantity, plural, one {Boleto} other {Bolets}}",
    "ticket_delivery_info": {
      "resend_my_tickets": "Reenviar Mis Boletos",
      "tickets_emailed_to": "Tus boletos fueron enviados al correo {email} en {deliveryDates}"
    },
    "ticket_delivery_summary_item": {
      "delivery_method_for_tiers": "Método de entrega para {tierNames}",
      "description": {
        "axs_mobile": "Sus boletos deben reclamarse en la aplicación AXS Mobile.",
        "digital": "Estas entradas en PDF se le enviarán por correo electrónico.",
        "flash_seats": "Sus boletos móviles deben ser reclamados en la aplicación móvil Flash Seats.",
        "online": "El enlace para el programa se le enviará por correo electrónico poco antes de que se publique.",
        "tm_mobile": "Sus boletos deben reclamarse en la aplicación Ticketmaster Mobile.",
        "will_call": "Estas entradas se reclamarán en la taquilla del recinto."
      }
    },
    "ticket_details_display": {
      "delivery_method": "Método de Entrega",
      "equal_requests": "Todas las solicitudes, independientemente de cuando fueron ingresadas, serán tratadas con la misma prioridad por el sistema de solicitud de boletos.",
      "fees": "tarifas",
      "for": "para",
      "requests_made_before": "Las solicitudes se pueden hacer en cualquier momento antes de",
      "ticket_details": "Detalles de Boletos",
      "upgrade_details": "Detalles de actualización"
    },
    "ticket_quantity": "{quantity, plural, one {# Boleto} other {# Boletos}}",
    "tickets": "Boletos",
    "tier_picker": {
      "complimentary": "Complementario",
      "multiple_options": "Puedes seleccionar múltiples opciones y los mejores boletos disponibles serán assignados.",
      "multiple_options_ticketless_upgrade": "Puede seleccionar varias opciones y se le asignará la mejor opción disponible. Tenga en cuenta: las mejoras NO incluyen entradas para el evento. Ya debe tener un boleto o debe comprar un boleto por separado.",
      "starts_at_unknown": "El máximo que se le cobrará es el precio que se muestra a continuación.",
      "unavailable": "No disponible",
      "which_tickets": "¿Cuáles boletos gustarías solicitar?",
      "which_tickets_free": "¿Qué boletos te gustaría?",
      "which_upgrades": "¿Qué actualizaciones le gustaría solicitar?"
    },
    "total": "Total",
    "unprocessable_entity": {
      "header": "Ups",
      "subheader": "Hubo un problema al acceder a esta página, comuníquese con <a class=\"underline\" href=\"mailto:support@seated.com?subject=Unable to visit ticket page\" data-test-email-link=\"\">support@seated.com</a> para obtener más información."
    },
    "up_to": "Hasta",
    "upgrades": "Actualizaciones",
    "venue": "Lugar de eventos",
    "verification_form": {
      "cc_month_placeholder": "MM",
      "cc_year_placeholder": "AA"
    },
    "waves_content": {
      "how_it_works": "Cómo funciona",
      "how_it_works_summary": "Esperamos una gran demanda de entradas. Para garantizar un proceso justo, lanzaremos las entradas en tandas. Cada tanda tendrá una cantidad limitada de entradas disponibles para su compra. Consulta los detalles de cada tanda más arriba."
    }
  }]];
});